body {
  margin: 0;
  font-family: 'Helvetica', 'Arial', sans-serif;  
  background-color: #FAFAFA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.gridView {
  display: grid;
}

.end {
  justify-self: end;
}

.center {
  justify-self: center;
}

.gafc {
  grid-auto-flow: column;
}

.cg1 {
  column-gap: 1em;
}
